<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <search-form></search-form>
    <v-row justify="center">
      <v-col xs="12" sm="12" md="12">
        <preloader v-if="this.load==1" :color="loaderColor" :scale="1"></preloader>
        <ktyp-details v-if="Object.keys( ktyp ).length > 0" :ktyp="ktyp"></ktyp-details>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
import api from "../../components/external/modules/shared/utils/api";
import Preloader from '../../components/external/modules/shared/views/WLoader.vue';
import KtypDetails from '../ktyp/KtypDetails.vue';
import SearchForm from './SearchForm.vue';

import {
  VCol,
  VContainer,
  VRow,
} from "vuetify/lib";

export default {
  components: {
    VCol,
    VContainer,
    VRow,

    SearchForm,
    KtypDetails,
    Preloader,
  },

  data() {
    return {
        ktyp: {},
        load: 1,
        loaderColor: "#0D47A1"
    };
  },

  methods: {
  },

  created() {
    let params = {
      kind: "ktypsearch",
      kTypNo: parseInt(this.$route.query.ktypno),
      lang: this.storedLang
    }

    api
      .post("/ktypsearch/search", params)
      .then((res) => {
        this.load = 0;
        if (res.data.status === 200 && res.data.entity !== null) {
          this.ktyp=res.data.entity;
        } else {
          console.log(new Error("Result status : "+res.data.status));
          console.log(res);
        }
      })
      .catch((err) => {
          console.log(new Error("Catching error : "));
          console.log(err);
      });
  },

  computed: {
    storedLang: function () {
      return localStorage.storedLang === undefined
      ? "FR"
      : localStorage.storedLang;
    },
  },
};
</script>

<style scoped>

</style>
