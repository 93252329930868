<template>

    <v-card class="elevation-12 mt-6">
        <v-card-title v-if="isDataTableLoading == true">
            Searching Articles
        </v-card-title>
        <v-card-title v-else-if="articles.length > 1">
            {{ articles.length }} Articles found
        </v-card-title>
        <v-card-title v-else-if="articles.length == 0">
            No Articles found
        </v-card-title>
        <v-card-title v-else>
            {{ articles.length }} Article found
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
            <v-text-field
                class="pl-4 pr-4"
                v-model="brandSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>

            <v-data-table
                class="mt-4"
                :items="articles"
                :headers="headersList"
                :search="brandSearch"
                sort-by1="brandName"
                sort-desc1
                :loading="isDataTableLoading"
                loading-text="Loading... Please wait"
                dense
            >
            <template v-slot:item.ktypInfo="{ item }">
              <span :title="item.ktypInfo.replaceAll('|', '\r\n')" v-if="item.ktypInfo != ''"><v-icon size="1.2em">mdi-information</v-icon></span>
              <!-- <span v-for="(info, i) in item.ktypInfo.split('|')" :key="i" :title="info.replace('|', '\r\n')">{{ info.split(':')[1] }}<br/></span> -->
            </template>
            <template v-slot:item.controls="props">
              <v-icon  @click="searchArticle(props.item)">mdi-eye</v-icon>
            </template>
            </v-data-table> 

        </v-card-text>
    </v-card>

</template>

<script>
import api from "../../components/external/modules/shared/utils/api";
import path from "../../shared/paths";

import {
  VCard,
  VCardText,
  VCardTitle,
  VDivider,
  VDataTable,
  VIcon,
  VTextField,
} from "vuetify/lib";

export default {
  name: 'articles-list',

  components: {
    VCard,
    VCardText,
    VCardTitle,
    VDivider,
    VDataTable,
    VIcon,
    VTextField,
  },

  props: {
    isFullList: { type: Boolean, default: false },
    articlesSearched: { type: Array, default: null },
    isLoading: { type: Boolean, default: null},
    fromKtyp: { type: Boolean, default: false}
  },

  data() {
    return {
      expanded: [],
      singleExpand: false,
      isDataTableLoading: this.isLoading == null ? false : this.isLoading,
      brandSearch: '',
      articles: this.articlesSearched == null ? [] : this.articlesSearched,
    }
  },

  computed: {
    headersList: function () {
        if (this.isFullList == true) {
          return [
              { value: "brandName", text: "Brand" },
              { value: "artNo", text: "Reference" },
              { value: "genart", text: "Genart" },
              { value: "countries", text: "Countries" },
              { value: "controls", text: "", sortable: false },
          ];
        }
        else if (this.fromKtyp == true) {
          return [
                { value: "brandName", text: "Brand" },
                { value: "artNo", text: "Reference" },
                { value: "ktypInfo", text: "Info" },
                { value: "controls", text: "", sortable: false },
            ];
        }
        else {
          return [
              { value: "brandName", text: "Brand" },
              { value: "artNo", text: "Reference" },
              { value: "genart", text: "Genart" },
              { value: "origin", text: "Origin" },
              { value: "controls", text: "", sortable: false },
          ];
        }
    },
    storedLang: function () {
      return localStorage.storedLang === undefined
      ? "FR"
      : localStorage.storedLang;
    },
  },

  methods: {
    searchArticle(article) {
      this.$router.push({
        name: path.TECDOCARTICLE,
        query: { brandno: article.brandNo, artno: article.artNo },
      });
    },
  },

  created() {
    if (this.isFullList == true) {
        this.isDataTableLoading = true;
        let params = {
            kind: "oerefarticlesearch",
            manNo: parseInt(this.$route.query.manno),
            manName: "",
            refNo: this.$route.query.refno,
            lang: this.storedLang
        }
    
        api
          .post("/oerefarticlesearch/search", params)
          .then((res) => {
            if (res.data.status === 200 && res.data.entity !== null) {
              if (res.data.entity.articles == null) {
                this.articles = []
              }
              else {
                this.articles = res.data.entity.articles;
              }
            } else {
              console.log(new Error("Result status : "+res.data.status));
              console.log(res);
            }
            this.isDataTableLoading = false;
          })
          .catch((err) => {
            console.log(new Error("Catching error : "));
            console.log(err);
            this.isDataTableLoading = false;
          });
    }
  },
}

</script>

<style scoped>
tr:not(:last-child) {
    border-bottom: 1px solid #b2cbfa !important;
}
td {
    border-bottom: inherit !important;
}
th {
    border-bottom: 2px solid #b2cbfa !important;
    font-size: 1.2em !important;
}
th:not(:last-child), td:not(:last-child) {
    border-right: 1px dotted #b2cbfa;
}

.v-icon:hover{
    cursor: pointer;
}
</style>
