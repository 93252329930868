<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-card class="elevation-12 mt-12 mb-6">
        <v-card-title class="Ktyp">
            {{ ktyp.manufacturer.manName }} {{ ktyp.model.name }}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-simple-table>
                <tr>
                  <td>Manufacturer</td>
                  <td>
                    {{ ktyp.manufacturer.manName }}
                    <v-tooltip v-model="showManufacturerTooltip" :open-on-hover="false" :absolute="true" right>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon @click="on.click" @blur="on.blur" retain-focus-on-click size="1.2em">mdi-information</v-icon>
                      </span>
                    </template>
                    <span><manufacturer :manufacturer="ktyp.manufacturer" /></span>
                  </v-tooltip>
                  </td>
                </tr>
                <tr>
                  <td>Model</td>
                  <td>
                    {{ ktyp.model.name }}
                    <v-tooltip v-model="showModelTooltip" :open-on-hover="false" :absolute="true" right>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon @click="on.click" @blur="on.blur" retain-focus-on-click size="1.2em">mdi-information</v-icon>
                      </span>
                    </template>
                    <span><model :model="ktyp.model" /></span>
                  </v-tooltip>
                  </td>
                </tr>
                <tr>
                  <td>Version</td>
                  <td>{{ ktyp.version }}</td>
                </tr>
                <tr>
                  <td>Body Type</td>
                  <td>{{ ktyp.bodyType == '' ? 'Unknown' : ktyp.bodyType }}</td>
                </tr>
                <tr>
                  <td>Dates</td>
                  <td>{{ ktyp.dateFrom }} - {{ ktyp.dateTo }}</td>
                </tr>
                <tr>
                  <td>KType Number</td>
                  <td>{{ ktyp.kTypNo }}</td>
                </tr>
                <tr>
                  <td>KBA Number</td>
                  <td>{{ ktyp.kbaNo }}</td>
                </tr>
                <tr>
                  <td>ChTyp (Swiss Number)</td>
                  <td>{{ ktyp.chTyp }}</td>
                </tr>
                <tr>
                  <td>STyp/NatCode (Austrian Number)</td>
                  <td>{{ ktyp.sTyp }}</td>
                </tr>
                <tr>
                  <td>Transmission</td>
                  <td>{{ ktyp.transmissionType == '' ? 'Unknown' : ktyp.transmissionType }}</td>
                </tr>
                <tr>
                  <td>Injection</td>
                  <td>{{ ktyp.injection == '' ? 'Unknown' : ktyp.injection }}</td>
                </tr>
                <tr>
                  <td>Propulsion</td>
                  <td>{{ ktyp.propulsion == '' ? 'Unknown' : ktyp.propulsion }}</td>
                </tr>
                <tr>
                  <td>Brake Type</td>
                  <td>{{ ktyp.brakeType == '' ? 'Unknown' : ktyp.brakeType }}</td>
                </tr>
                <tr>
                  <td>Countries</td>
                  <td>{{ ktyp.countries == '' ? 'Unknown' : ktyp.countries }}</td>
                </tr>
              </v-simple-table>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-simple-table>
                <tr>
                  <td>Brake System</td>
                  <td>{{ ktyp.brakeSystem == '' ? 'Unknown' : ktyp.brakeSystem }}</td>
                </tr>
                <tr>
                  <td>Catalyseur Type</td>
                  <td>{{ ktyp.catalyseurType == '' ? 'Unknown' : ktyp.catalyseurType }}</td>
                </tr>
                <tr>
                  <td>Power</td>
                  <td>{{ ktyp.powerKw == -1 ? 'Unknown' : ktyp.powerKw }} kw, {{ ktyp.powerHorses == -1 ? 'unknown' : ktyp.powerHorses }} hp</td>
                </tr>
                <tr>
                  <td>Capacity</td>
                  <td>{{ ktyp.engineCapacityTech == -1 ? 'Unknown' : ktyp.engineCapacityTech + 'cc' }} ({{ ktyp.engineCapacityTax == -1 ? 'unknown' : ktyp.engineCapacityTax }} for taxes)</td>
                </tr>
                <tr>
                  <td>Capacity volume</td>
                  <td>{{ ktyp.engineCapacityLiters == -1 ? 'Unknown' : ktyp.engineCapacityLiters + ' litres' }}</td>
                </tr>
                <tr>
                  <td>Energy</td>
                  <td>{{ ktyp.energy == '' ? 'Unknown' : ktyp.energy }}</td>
                </tr>
                <tr>
                  <td>Engine Type</td>
                  <td>{{ ktyp.engineType == '' ? 'Unknown' : ktyp.engineType }}</td>
                </tr>
                <tr>
                  <td>Cylinders</td>
                  <td>{{ ktyp.cylinders == -1 ? 'Unknown' : ktyp.cylinders }}</td>
                </tr>
                <tr>
                  <td>Valves per cylinder</td>
                  <td>{{ ktyp.valves == -1 ? 'Unknown' : ktyp.valves }}</td>
                </tr>
                <tr>
                  <td>Voltage</td>
                  <td>{{ ktyp.voltage == -1 ? 'Unknown' : ktyp.voltage }}</td>
                </tr>
                <tr>
                  <td>Doors</td>
                  <td>{{ ktyp.doors == -1 ? 'Unknown' : ktyp.doors }}</td>
                </tr>
                <tr>
                  <td>Tank capacity</td>
                  <td>{{ ktyp.tankCapacityLiters == -1 ? 'Unknown' : ktyp.tankCapacityLiters + ' litres' }}</td>
                </tr>
                <tr>
                  <td>ABS</td>
                  <td>{{ ktyp.abs == 1 ? 'Yes' : (ktyp.abs == 0 ? 'No' : 'Unknown') }}</td>
                </tr>
                <tr>
                  <td>TCS</td>
                  <td>{{ ktyp.tcs == 1 ? 'Yes' : (ktyp.tcs == 0 ? 'No' : 'Unknown') }}</td>
                </tr>
                <tr>
                  <td>Engine(s)</td>
                  <td>
                    <p class="mb-0 pt-2 pd-2" v-for="(engine, i) in ktyp.engines" :key="i">
                      {{ engine.engineCode }}
                    
                    <v-tooltip v-model="engine.show" :open-on-hover="false" :absolute="true" right>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        :engine="engine"
                      >
                        <v-icon @click="on.click" @blur="on.blur" retain-focus-on-click size="1.2em">mdi-information</v-icon>
                      </span>
                    </template>
                    <span><engine :engine="engine" /></span>
                  </v-tooltip>
                    </p>
                  </td>
                </tr>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
    </v-card>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-card class="elevation-12 mt-6">
          <v-card-title>
            GenArts linked to this vehicle
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text>
            <v-text-field
                class="pl-4 pr-4"
                v-model="genartSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>

            <v-data-table
                class="mt-4"
                :items="ktyp.genarts"
                :headers="headers"
                :search="genartSearch"
                loading-text="Loading... Please wait"
                dense
            >
            <template v-slot:item.controls="props">
              <v-btn @click="searchKtypGenartArticles(props.item)">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            </v-data-table> 
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <articles-list v-if="articles != null" :from-ktyp="fromKtyp" :articles-searched="articles" :is-loading="isDataTableLoading" :key="key"></articles-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "../../components/external/modules/shared/utils/api";

import Manufacturer from './Manufacturer.vue';
import Model from './Model.vue';
import Engine from './Engine.vue';
import ArticlesList from '../article/ArticlesList.vue';

import {
  VCard,
  VCardText,
  VCardTitle,
  VDivider,
  VSimpleTable,
  VCol,
  VContainer,
  VRow,
  VTooltip,
  VIcon,
  VDataTable,
  VTextField,
} from "vuetify/lib";

export default {
  name: 'ktyp-details',

  props: {
    ktyp: { type: Object, default: null }
  },

  components: {
    VCard,
    VCardText,
    VCardTitle,
    VDivider,
    VSimpleTable,
    VCol,
    VContainer,
    VRow,
    VTooltip,
    VIcon,
    VDataTable,
    //VSpacer,
    VTextField,
    Manufacturer,
    Model,
    Engine,
    ArticlesList,
  },

  methods: {
    searchKtypGenartArticles(genart) {
      let params = {
          kind: "ktypgenartarticlesearch",
          ktypNo: parseInt(this.$route.query.ktypno),
          genartNo: genart.genartNo,
          lang: this.storedLang
      }
      this.isDataTableLoading = true;
      this.articles = []
      this.key++;
  
      api
        .post("/ktypgenartarticlesearch/search", params)
        .then((res) => {
          if (res.data.status === 200 && res.data.entity !== null) {
            if (res.data.entity.articles == null) {
              this.articles = [];
            }
            else {
              this.articles = res.data.entity.articles;
            }
          } else {
            console.log(new Error("Result status : "+res.data.status));
            console.log(res);
          }
          this.isDataTableLoading = false;
          this.key++;
        })
        .catch((err) => {
          console.log(new Error("Catching error : "));
          console.log(err);
          this.isDataTableLoading = false;
          this.key++;
        });
    },
  },

  data() {
    return {
      showManufacturerTooltip: "",
      showModelTooltip: "",
      showEngineTooltip: "",
      genartSearch: "",
      articles: null,
      key: 0,
      isDataTableLoading: true,
      fromKtyp: true,
    }
  },

  created() {
    //this.manufacturertitle = "ManNo : " + this.ktyp.manNo;
  },

  computed: {
    headers: function () {
      return [
        { value: "genartNo", text: "GenArtNo" },
        { value: "genartName", text: "Name" },
        { value: "controls", text: "", sortable: false },
      ];
    },
    storedLang: function () {
      return localStorage.storedLang === undefined
      ? "FR"
      : localStorage.storedLang;
    },
  },
}



</script>

<style scoped>
td {
  padding: 6px !important;
  border-bottom: 1px solid #b2cbfa !important;
}

th:not(:last-child), td:not(:last-child) {
    border-right: 1px dotted #b2cbfa;
}

.v-icon:hover{
    cursor: pointer;
}
</style>
