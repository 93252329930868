<template>
    <v-row justify="center">
        <v-card class="elevation-12 mt-12 mb-12" width="80%">
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="6" xs="6" sm="6" md="8" lg="8" xl="8">
                  <v-text-field
                    v-model="searchParam"
                    :rules="rules.search"
                    prepend-icon="mdi-magnify"
                    label="Search"
                    required
                    solo
                    @keydown.enter.prevent="submitsearch"
                    class="mytextfield"
                    @click="getHistory"
                  />
                </v-col>
                <v-col cols="3" xs="3" sm="3" md="2" lg="2" xl="2">
                    <v-btn :disabled="!valid" color="primary" @click="submitsearch">submit</v-btn>
                </v-col>
                <v-col cols="3" xs="3" sm="3" md="2" lg="2" xl="2">
                    <v-select v-model="lang" :items="countries" item-text="countryCode" item-value="countryCode" @change="changeLang"></v-select>
                </v-col>
              </v-row>
              <v-row v-show="isOpen">
                <v-col cols="10"><div class="ml-8 pl-4" style="text-align:center">Last searches<v-icon class="float-right" @click="isOpen=!isOpen">mdi-close</v-icon></div></v-col>
                <v-col v-for="(previousSearch, i) in storedSearches" :key="i" cols="10">
                  <v-btn block class="pl-4 ml-4 fill-width" @click="searchAll(previousSearch)">{{ previousSearch }}</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
    </v-row>
</template>


<script>
import path from "../../shared/paths";

import {
  VBtn,
  VCard,
  VCardText,
  VCol,
  VForm,
  VRow,
  VTextField,
  VIcon,
  VSelect,
} from "vuetify/lib";

export default {
    name: 'search-form',
  components: {
    VBtn,
    VCard,
    VCardText,
    VCol,
    VForm,
    VRow,
    VTextField,
    VIcon,
    VSelect,
  },

  emits: ['searchAll'],

  data() {
    return {
        rules: {
            search: [
                (v) => !!v || "Search is required",
                (v) => (v && v.length >= 2) || "Search must be more than 2 characters",
            ],
        },
        valid : true,
        searchParam: this.search != "" ? this.search : "",
        isOpen: false,
        lang: {countryCode: this.storedLang},
        countries: [
            {countryCode: "FR"}, 
            {countryCode: "EN"}
        ],
    };
  },

  created() {
      this.lang = {countryCode: this.storedLang};
  },

  props: {
      search: { type: String, default: ""}
  },

  computed: {
    storedSearches: function () {
      return localStorage.storedSearches === undefined
      ? []
      : [...JSON.parse(localStorage.storedSearches)];
    },
    storedLang: function () {
      return localStorage.storedLang === undefined
      ? "FR"
      : localStorage.storedLang;
    },
  },

  methods: {
    changeLang(param) {
        localStorage.storedLang = param;
        this.lang = {countryCode: param};
        this.$router.go();
    },
    getHistory() {
        this.isOpen = true;
    },
    submitsearch() {
      if (this.$refs.form.validate()) {
        this.searchAll(this.searchParam);
      }
    },
    searchAll(param) {
        this.isOpen = false;
        if (this.storedSearches.includes(param)) {
            for( var i = 0; i < this.storedSearches.length; i++){ 
                if ( this.storedSearches[i] === param) { 
                    this.storedSearches.splice(i, 1); 
                }
            }
            this.storedSearches.unshift(param);
        }
        else if (this.storedSearches.length >= 5) {
            this.storedSearches.pop();
            this.storedSearches.unshift(param);
        }
        else {
            this.storedSearches.unshift(param);
        }
        localStorage.storedSearches = JSON.stringify(this.storedSearches);
        if (this.$router.history.current.name == path.TECDOCSEARCH) {
          this.$emit('searchAll', param);
        }
        this.$router.push({
            name: path.TECDOCSEARCH,
            params: { search: param, lang: this.storedLang },
        }).catch(()=>{}); //the catch here is to avoid the exception launched when trying to make another search while already on this page
    }
  },
};
</script>

<style scoped>

</style>
