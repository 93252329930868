<template>
    <v-card>
        <v-card-title class="Manufacturer">
            Manufacturer
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
            <v-simple-table>
              <tr>
                <td>Manufacturer Number</td>
                <td>{{ manufacturer.manNo }}</td>
              </tr>
              <tr>
                <td>KBA Number</td>
                <td>{{ manufacturer.kbaNo }}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{{ manufacturer.manName }}</td>
              </tr>
              <tr>
                <td>Code</td>
                <td>{{ manufacturer.manCode }}</td>
              </tr>
              <tr>
                <td>Passenger car ?</td>
                <td>{{ manufacturer.pc == 1 ? 'Yes' : (manufacturer.pc == 0 ? 'No' : 'Unknown') }}</td>
              </tr>
              <tr>
                <td>Commercial vehicle ?</td>
                <td>{{ manufacturer.cv == 1 ? 'Yes' : (manufacturer.cv == 0 ? 'No' : 'Unknown') }}</td>
              </tr>
              <tr>
                <td>Light commercial vehicle ?</td>
                <td>{{ manufacturer.lcv == 1 ? 'Yes' : (manufacturer.lcv == 0 ? 'No' : 'Unknown') }}</td>
              </tr>
              
            </v-simple-table>
        </v-card-text>
    </v-card>

</template>

<script>
//import api from "../../components/external/modules/shared/utils/api";

import {
  //VBtn,
  VCard,
  //VCardActions,
  VCardText,
  VCardTitle,
  VDivider,
  VSimpleTable,
  //VDataTable,
  //VSelect,
  //VCol,
  //VContainer,
//   VForm,
//   VRow,
  //VSpacer,
  //VTextField,
} from "vuetify/lib";

export default {
  name: 'manufacturer',

  components: {
    // VBtn,
    VCard,
    //VCardActions,
    VCardText,
    VCardTitle,
    VDivider,
    VSimpleTable,
    //VDataTable,
    //VSelect,
    // VCol,
    //VContainer,
    // VForm,
    // VRow,
    //VSpacer,
    //VTextField,
  },

  props: {
    manufacturer: { type: Object, default: null }
  },

  data() {
    return {
      
    }
  },

  computed: {
    
  },

  methods: {
    
  },

  created() {
  //   let params = {
  //       kind: "articlesearch",
  //       manufacturerNo: this.manufacturerNo
  //   }

  //   api
  //       .post("/manufacturer/search", params)
  //       .then((res) => {
  //       if (res.data.status === 200 && res.data.entity !== null) {
  //           if (res.data.entity == null) {
  //               this.manufacturer = []
  //           }
  //           else {
  //               this.manufacturer = res.data.entity;
  //           }
  //       } else {
  //           console.log(new Error("Result status : "+res.data.status));
  //           console.log(res);
  //       }
  //   })
  //   .catch((err) => {
  //       console.log(new Error("Catching error : "));
  //       console.log(err);
  //   });
  }
}

</script>

<style scoped>
td {
  padding: 6px !important;
  border-bottom: 1px solid #b2cbfa !important;
}

th:not(:last-child), td:not(:last-child) {
    border-right: 1px dotted #b2cbfa;
}
</style>
