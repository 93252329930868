<template>
    <v-card>
        <v-card-title class="Engine">
            Engine
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-simple-table>
                <tr>
                  <td>Manufacturer</td>
                  <td>{{ engine.manufacturer }}</td>
                </tr>
                <tr>
                  <td>Engine Number</td>
                  <td>{{ engine.engineNo }}</td>
                </tr>
                <tr>
                  <td>Engine Code</td>
                  <td>{{ engine.engineCode }}</td>
                </tr>
                <tr>
                  <td>Dates</td>
                  <td>{{ engine.dateFrom }} - {{ engine.dateTo }}</td>
                </tr>
                <tr>
                  <td>Power (kw)</td>
                  <td>{{ engine.kwFrom == -1 && engine.kwTo == -1 ? 'Unknown' : (engine.kwFrom == -1 ? "From " + 0 : (engine.kwTo == -1 ? engine.kwFrom : "From " + engine.kwFrom)) }} {{ engine.kwTo == -1 ? '' : "to " + engine.kwTo }}</td>
                </tr>
                <tr>
                  <td>Power (hp)</td>
                  <td>{{ engine.hpFrom == -1 && engine.hpTo == -1 ? 'Unknown' : (engine.hpFrom == -1 ? "From " + 0 : (engine.hpTo == -1 ? engine.hpFrom : "From " + engine.hpFrom)) }} {{ engine.hpTo == -1 ? '' : "to " + engine.hpTo }}</td>
                </tr>
                <tr>
                  <td>Capacity in cc (real)</td>
                  <td>{{ engine.ccTechFrom == -1 && engine.ccTechTo == -1 ? 'Unknown' : (engine.ccTechFrom == -1 ? "From " + 0 : (engine.ccTechTo == -1 ? engine.ccTechFrom : "From " + engine.ccTechFrom)) }} {{ engine.ccTechTo == -1 ? '' : "to " + engine.ccTechTo }}</td>
                </tr>
                <tr>
                  <td>Capacity in cc (tax)</td>
                  <td>{{ engine.ccTaxFrom == -1 && engine.ccTaxTo == -1 ? 'Unknown' : (engine.ccTaxFrom == -1 ? "From " + 0 : (engine.ccTaxTo == -1 ? engine.ccTaxFrom : "From " + engine.ccTaxFrom)) }} {{ engine.ccTaxTo == -1 ? '' : "to " + engine.ccTaxTo }}</td>
                </tr>
                <tr>
                  <td>Capacity in liters (real)</td>
                  <td>{{ engine.literTechFrom == -1 && engine.literTechTo == -1 ? 'Unknown' : (engine.literTechFrom == -1 ? "From " + 0 : (engine.literTechTo == -1 ? engine.literTechFrom : "From " + engine.literTechFrom)) }} {{ engine.literTechTo == -1 ? '' : "to " + engine.literTechTo }}</td>
                </tr>
                <tr>
                  <td>Capacity in liters (tax)</td>
                  <td>{{ engine.literTaxFrom == -1 && engine.literTaxTo == -1 ? 'Unknown' : (engine.literTaxFrom == -1 ? "From " + 0 : (engine.literTaxTo == -1 ? engine.literTaxFrom : "From " + engine.literTaxFrom)) }} {{ engine.literTaxTo == -1 ? '' : "to " + engine.literTaxTo }}</td>
                </tr>
                <tr>
                  <td>Valves</td>
                  <td>{{ engine.valves == -1 ? 'Unknown' : engine.valves }}</td>
                </tr>
                <tr>
                  <td>Cylinders</td>
                  <td>{{ engine.cylinders == -1 ? 'Unknown' : engine.cylinders }}</td>
                </tr>
                <tr>
                  <td>Compression</td>
                  <td>{{ engine.compressionFrom == -1 && engine.compressionTo == -1 ? 'Unknown' : (engine.compressionFrom == -1 ? "From " + 0 : (engine.compressionTo == -1 ? engine.compressionFrom : "From " + engine.compressionFrom)) }} {{ engine.compressionTo == -1 ? '' : "to " + engine.compressionTo }}</td>
                </tr>
                <tr>
                  <td>Torque</td>
                  <td>{{ engine.torqueFrom == -1 && engine.torqueTo == -1 ? 'Unknown' : (engine.torqueFrom == -1 ? "From " + 0 : (engine.torqueTo == -1 ? engine.torqueFrom : "From " + engine.torqueFrom)) }} {{ engine.torqueTo == -1 ? '' : "to " + engine.torqueTo }}</td>
                </tr>
                <tr>
                  <td>RPM Torque</td>
                  <td>{{ engine.rpmTorqueFrom == -1 && engine.rpmTorqueTo == -1 ? 'Unknown' : (engine.rpmTorqueFrom == -1 ? "From " + 0 : (engine.rpmTorqueTo == -1 ? engine.rpmTorqueFrom : "From " + engine.rpmTorqueFrom)) }} {{ engine.rpmTorqueTo == -1 ? '' : "to " + engine.rpmTorqueTo }}</td>
                </tr>
                <tr>
                  <td>RPM Kw</td>
                  <td>{{ engine.rpmKwFrom == -1 && engine.rpmKwTo == -1 ? 'Unknown' : (engine.rpmKwFrom == -1 ? "From " + 0 : (engine.rpmKwTo == -1 ? engine.rpmKwFrom : "From " + engine.rpmKwFrom)) }} {{ engine.rpmKwTo == -1 ? '' : "to " + engine.rpmKwTo }}</td>
                </tr>
              </v-simple-table>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-simple-table>
                <tr>
                  <td>Engine Usage</td>
                  <td>{{ engine.engineUse }}</td>
                </tr>
                <tr>
                  <td>Engine Design</td>
                  <td>{{ engine.engineDesign }}</td>
                </tr>
                <tr>
                  <td>Fuel Type</td>
                  <td>{{ engine.fuelType }}</td>
                </tr>
                <tr>
                  <td>Fuel Mixture Formation</td>
                  <td>{{ engine.fuelMixForm }}</td>
                </tr>
                <tr>
                  <td>Engine Aspiration</td>
                  <td>{{ engine.engineAspiration }}</td>
                </tr>
                <tr>
                  <td>Crankshaft bearings</td>
                  <td>{{ engine.crankshaftBearings == -1 ? 'Unknown' : engine.crankshaftBearings }}</td>
                </tr>
                <tr>
                  <td>Bore</td>
                  <td>{{ engine.bore == -1 ? 'Unknown' : engine.bore }}</td>
                </tr>
                <tr>
                  <td>Stroke</td>
                  <td>{{ engine.stroke == -1 ? 'Unknown' : engine.stroke }}</td>
                </tr>
                <tr>
                  <td>Engine Type</td>
                  <td>{{ engine.engineType }}</td>
                </tr>
                <tr>
                  <td>Exhaust Norm</td>
                  <td>{{ engine.exhaustNorm }}</td>
                </tr>
                <tr>
                  <td>Cylinders Design</td>
                  <td>{{ engine.cylindersDesign }}</td>
                </tr>
                <tr>
                  <td>Engine Management</td>
                  <td>{{ engine.engineManagement }}</td>
                </tr>
                <tr>
                  <td>Valve Control</td>
                  <td>{{ engine.valveControl }}</td>
                </tr>
                <tr>
                  <td>Cooling Type</td>
                  <td>{{ engine.coolingType }}</td>
                </tr>
                <tr>
                  <td>Sales Description</td>
                  <td>{{ engine.salesDescription }}</td>
                </tr>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
    </v-card>

</template>

<script>
//import api from "../../components/external/modules/shared/utils/api";

import {
  //VBtn,
  VCard,
  //VCardActions,
  VCardText,
  VCardTitle,
  VDivider,
  VSimpleTable,
  //VDataTable,
  //VSelect,
  VCol,
  //VContainer,
//   VForm,
  VRow,
  //VSpacer,
  //VTextField,
} from "vuetify/lib";

export default {
  name: 'engine',

  components: {
    // VBtn,
    VCard,
    //VCardActions,
    VCardText,
    VCardTitle,
    VDivider,
    VSimpleTable,
    //VDataTable,
    //VSelect,
    VCol,
    //VContainer,
    // VForm,
    VRow,
    //VSpacer,
    //VTextField,
  },

  props: {
    engine: { type: Object, default: null }
  },

  data() {
    return {
      
    }
  },

  computed: {
    
  },

  methods: {
    
  },

  created() {
  //   let params = {
  //       kind: "articlesearch",
  //       engineNo: this.engineNo
  //   }

  //   api
  //       .post("/engine/search", params)
  //       .then((res) => {
  //       if (res.data.status === 200 && res.data.entity !== null) {
  //           if (res.data.entity == null) {
  //               this.engine = []
  //           }
  //           else {
  //               this.engine = res.data.entity;
  //           }
  //       } else {
  //           console.log(new Error("Result status : "+res.data.status));
  //           console.log(res);
  //       }
  //   })
  //   .catch((err) => {
  //       console.log(new Error("Catching error : "));
  //       console.log(err);
  //   });
  }
}

</script>

<style scoped>
td {
  padding: 6px !important;
  border-bottom: 1px solid #b2cbfa !important;
}

th:not(:last-child), td:not(:last-child) {
    border-right: 1px dotted #b2cbfa;
}
</style>
