import { render, staticRenderFns } from "./ktyp.vue?vue&type=template&id=2adc134e&scoped=true&"
import script from "./ktyp.vue?vue&type=script&lang=js&"
export * from "./ktyp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2adc134e",
  null
  
)

export default component.exports